.trackerTimeline{
    gap: 8px;
    width: 100%;
    height: auto;
    background-color: #F3F4F6;
  }
  
  .track .vDivider{
    width:8px;
    height:calc(100% - 16px);
  }
  
  .track .progressTrack{
    grid-template-rows: repeat(4, 48px auto) 16px 48px;
    gap: 8px;
    width:100%;
  }

  .track .progressTrack.completed{
    grid-template-rows: repeat(4, 48px auto) 16px 48px 200px;
    gap: 8px;
    width:100%;
  }

  .track .progressTrack > .category, .track .progressTrack > .header{
    grid-template-columns: 36px 1fr 8px;
    display: grid;
    width: 100%;
    gap: 8px;
  }
  
  .track .progressTrack > .header{
    font-size: 20px;
  }
  
  .track .progressTrack > .header .bold{
    grid-template-columns: 48px 1fr 48px;
  }
  
  .bullet{
    width:8px;
    height:8px;
    border-radius:50%;
    background-color:gray;
  }
  
  .progressTrack .category > :nth-child(2):not(.closedCat){
    display:grid;
    grid-template-rows: auto;
    gap:8px;
    border:solid 2px gray;
    padding: 8px;
    border-radius:5px;
    width: 100%;
  }
  
  .track .categoryBlock{
    border:solid 2px gainsboro;
    background-color: white;
    height:48px;
    width: 100%;
    grid-template-columns: 48px 1fr auto 48px;
    color:dimgray;
  }
  
  .track .categoryBlock.inactive{
    border:dashed 2px gray;
    background-color: #F3F4F6;
  }
  
  .track .categoryBlock.inactive svg{
    color:dimgray;
  }

  .track .categoryBlock.next{
    grid-template-columns: 48px 1fr auto auto;
  }

  .categoryBlock.next .checkStatus{
    color:dimgray;
    display:grid;
    grid-template-columns: auto 48px;
    gap:8px;
  }

  .categoryBlock.failed .checkStatus svg{
    color:#c62828;
  }
  
  /* First Section */
  .track .first .vDivider,.track .first .bullet{
    background-color:#fdd835;
  }
  
  .track .first.header, .track .first.header a{
    color:#f57f17;
  }
  
  .track .first .catWrap{
    border-color:#fdd835 !important;
    background-color: #fffde7;
  }
  .track .first .categoryBlock{
    border-color:#fdd835;
  }
  
  .track .first .categoryBlock:not(.inactive):hover{
    background-color:#fff59d;
  }
  
  /* Second Section */
  .track .second .vDivider,.track .second .bullet{
    background-color:#8bc34a;
  }
  
  .track .second.header, .track .second.header a{
    color:#33691e;
  }
  
  .track .second .catWrap{
    border-color:#8bc34a !important;
    background-color: #f1f8e9;
  }
  
  .track .second .categoryBlock{
    border-color:#8bc34a;
  }
  
  .track .second .categoryBlock:not(.inactive):hover{
    background-color:#c5e1a5;
  }
  
  /* Third Section */
  .track .third .vDivider,.track .third .bullet{
    background-color:#4caf50;
  }
  
  .track .third.header, .track .third.header a{
    color:#1b5e20;
  }
  
  .track .third .catWrap{
    border-color:#4caf50 !important;
    background-color: #e8f5e9;
  }
  
  .track .third .categoryBlock{
    border-color:#4caf50;
  }
  
  .track .third .categoryBlock:not(.inactive):hover{
    background-color:#a5d6a7;
  }
  
  /* Fourth Section */
  .track .fourth .vDivider,.track .fourth .bullet{
    background-color:#1e88e5;
  }
  
  .track .fourth.header, .track .fourth.header a{
    color:#1565c0;
  }
  
  .track .fourth .catWrap{
    border-color:#1565c0 !important;
    background-color: #e3f2fd;
  }
  
  .track .fourth .categoryBlock{
    border-color:#1565c0;
  }
  
  .track .fourth .categoryBlock:not(.inactive):hover{
    background-color:#90caf9;
  }
  
  /* Fifth Section */
  .track .fifth .vDivider,.track .fifth .bullet{
    background-color:dimgray;
  }
   
  .track .fifth.divider{
    height:4px;
    border-radius: 4px;
    background-color:dimgray;
  }
  .track .fifth.divider.closed{
    background-color:#c62828;
  }
  .track .fifth.divider.completed{
    background-color:#ef6c00;
  }
  .track .fifth.header{
    color:dimgray;
  }
  .track .fifth.header.closed{
    color:#c62828 ;
  }
  .track .fifth.header.completed{
    color:#ef6c00 ;
  }


  .track .fifth .catWrap{
    border-color:#1565c0;
    background-color: #e3f2fd;
  }
  
  .track .fifth .categoryBlock{
    border-color:#1565c0;
  }
  
  .track .fifth .categoryBlock:not(.inactive):hover{
    background-color:#90caf9;
  }
  
  .category.fifth{
    grid-template-columns: 36px repeat(3, 1fr) 36px !important;
    gap:8px;
  }
  
  .fifth .closedCat{
    display:grid;
    grid-template-rows: auto 1fr auto;
    gap:8px;
    padding: 8px;
    border-radius:5px;
    width: 100%;
    background-color: white;
    color:dimgray;
    font-size: 18px;
  }
  
  .fifth .closedCat.inactive{
    border:dashed 2px gray;
    background-color: #F3F4F6;
  }
  
  .fifth .closedCat.inactive:hover{
    background-color: #F3F4F6;
  }
  
  .fifth .closedCat.inactive svg{
    color:dimgray;
  }
  
  .fifth .closedCat svg{
    width:48px;
    height:48px;
  }
  
  .closedCat.won{
    border:solid 2px #e65100;
  }
  .closedCat.won:hover{
    background-color: #fff3e0;
  }
  
  .closedCat.lost{
    border:solid 2px #ef5350;
  }
  .closedCat.lost:hover{
    background-color: #ffebee;
  }
  
  .closedCat.onHold{
    border:solid 2px #ff7043;
  }
  .closedCat.onHold:hover{
    background-color: #fbe9e7;
  }

  .category.inactive .vDivider, .header.inactive .bullet{
    background-color: gray;
  }
  .category.inactive .catWrap{
    border-color: #E2E2E2 !important;
    background-color: #EFEFEF !important;
  }
  .category.inactive .catWrap{
    border-color: #E2E2E2 !important;
    background-color: #F3F4F6 !important;
  }
  .category.inactive .categoryBlock{
    border-color: #E2E2E2 !important;
    background-color: #F3F4F6 !important;
  }
  .category.inactive *{
    color: #E2E2E2 !important;
  }

  .track .header.inactive{
    color:gray;
  }

  .policyProgressionBlock{
    grid-template-rows: 64px 1fr;
  }

  .policyProgressionBlock .stages{
    color: dimgray;
    grid-template-columns: 40px 48px 1fr 48px 1fr 48px 1fr 48px 1fr 48px 40px;
    gap:4px;
  }


  .policyProgressionBlock .progressBar{
    grid-template-columns: 40px 48px 1fr 48px 1fr 48px 1fr 48px 1fr 48px 40px;
    gap:4px;
  }

  .policyProgressionBlock .progressBar .data{
    width:100%;
    height:8px;
    position: relative;
  }

  .policyProgressionBlock .progressBar .progress{
    position: absolute;
    left:0;
    height:100%;
    z-index: 0;
  }

  .policyProgressionBlock .progressBar > div{
    border:solid;
    height:48px;
    position: relative;
    border-width:2px;
    overflow: hidden;
  }
  .policyProgressionBlock .progressBar .step{
    border-radius:50%;
  }
  .policyProgressionBlock .progressBar .data{
    border-radius: 5px;
  }
  .policyProgressionBlock .progressBar .completed .icon{
    border-radius: 50%;
    background-color: inherit;
    border: 2px solid;
    border-color: inherit;
    z-index: 1;
    width:36px;
    height:36px;
  }
  .policyProgressionBlock .progressBar svg{
    height:24px;
    width:24px;
  }

  .policyProgressionBlock .progressBar .completionMark{
    position: absolute;
    width:100%;
    height:100%;
  }

  .policyProgressionBlock .progressBar > div.inactive{
    border-color: gray !important;
    color: dimgray !important;
    border-width:2px;
    background-color: #EFEFEF !important;
  }

  .policyProgressionBlock:not(.inactive) .progressBar > div.inactive .icon{
    border-color: transparent !important;
  }

  .policyProgressionBlock:not(.inactive) .progressBar .first:not(.inactive){
    border-color: #f57f17;
    color: #f57f17;
    background-color: #fffde7;
  }
  .policyProgressionBlock:not(.inactive) .progressBar .first:not(.inactive) .progress, .first:not(.inactive) .completionMark{
    background-color: #fdd835;
  }

  .policyProgressionBlock:not(.inactive) .progressBar .second:not(.inactive){
    color:#33691e;
    background-color: #f1f8e9;
  }
  .policyProgressionBlock:not(.inactive) .progressBar .second:not(.inactive) .progress, .second:not(.inactive) .completionMark{
    background-color: #8bc34a;
  }

  .policyProgressionBlock:not(.inactive) .progressBar .third:not(.inactive){
    color: #1b5e20;
    background-color: #e8f5e9;
  }
  .policyProgressionBlock:not(.inactive) .progressBar .third:not(.inactive) .progress, .third:not(.inactive) .completionMark{
    background-color: #4caf50;
  }

  .policyProgressionBlock:not(.inactive) .progressBar .fourth:not(.inactive){
    border-color: #1565c0;
    color:#1565c0;
    background-color:#e3f2fd;
  }
  .policyProgressionBlock:not(.inactive) .progressBar .fourth:not(.inactive) .progress, .fourth:not(.inactive) .completionMark{
    background-color: #1e88e5;
  }

  .policyProgressionBlock .progressBar .fifth{
    border-color:#ef6c00;
    color:#ef6c00;
    background-color: #fff3e0;
  }

  .policyProgressionBlock .progressBar .fifth .progress, .fifth .completionMark{
    background-color: #fb8c00;
  }

  .policyProgressionBlock.inactive  .fifth .completionMark{
    background-color: #ef5350 !important;
  }
 
  .policyProgressionBlock.inactive .progressBar > .fifth{
    border-color: #c62828 !important;
    color:#c62828 !important;
    background-color:#ffebee !important;
  }

  .policyProgressionBlock.inactive .fifth .progress{
    background-color:#ffebee !important;
  }

  .policyProgressionBlock.inactive .fifth .stages{
    color:#c62828 !important;
  }
 
  .policyProgressionBlock.inactive .progressBar > div{
    border-color: dimgray !important;
    color:dimgray !important;
    background-color:#EFEFEF !important;
  }

  .policyProgressionBlock.inactive .progress{
    background-color:#EFEFEF !important;
  }

  .policyProgressionBlock.inactive .stages{
    color:dimgray !important;
    gap:4px;
  }

  .policyProgressionBlock.inactive .step:not(.fifth) .completionMark{
    background-color: #EFEFEF !important;
  }

  .policyProgressionBlock.inactive .step:not(.fifth)  .icon{
    border-color: #EFEFEF !important;
    background-color: transparent !important;
  }

  .progressTrack.inactive .header:not(.fifth),
  .progressTrack.inactive .categoryBlock:not(.failed) .icon svg,
  .progressTrack.inactive .categoryBlock:not(.failed) .checkStatus svg{
    color:gray !important;
  }

  .progressTrack.inactive .bullet, .progressTrack.inactive .vDivider{
    background-color: gray;
  }

  .progressTrack.inactive .catWrap, .progressTrack.inactive .categoryBlock{
    border-color: gray !important;
    background-color: transparent !important;
  }

  .progressTrack.inactive .categoryBlock.failed{
    border-color: #c62828 !important;
    background-color: #ffebee !important;
    color:#c62828 !important;
  }

  .progressTrack.inactive .categoryBlock.failed .icon svg{
    color:#c62828 !important;
  }
body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height:100vh;
  min-width:100vw;
  overflow: hidden;
  height: 100vh;
  background-color: #C5D5D6;
}

body *{
  box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  min-height:100vh;
  min-width:100vw;
}

.App{
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 64px 1fr;
  grid-template-rows: 64px 1fr;
  height:100vh;
  background-color: #C5D5D6;
}

.moduleBlock{
  -moz-box-shadow: 0 0 3px gray;
  -webkit-box-shadow: 0 0 3px gray;
  box-shadow: 0 0 8px gray;
}

.activity{
  overflow: auto;
  grid-template-rows: 48px calc(100% - 48px);
}
.activity.timeline{
  grid-template-rows:84px 1fr;
}

.activity.timeline > div:nth-child(2){
  overflow: hidden;
}
.content{
  background-color: #F3F4F6;
  border:1px solid gainsboro;
}

.details .content{
  background-color: inherit;
  border:1px solid transparent;
  overflow: auto;
  height: auto;
  width:100%;
}

.details .content > div{
  height: auto;
}

.details .content .formBlock{
  height: auto;
}

.details .content .policyForm{
  height: auto;
}

.content > div{
  overflow-y: auto;
}

.prompt.alt{
  grid-template-rows: 36px repeat(auto-fill, 24px);
}

.prompt.alt > div{
  grid-template-columns: 24px 1fr;
}

.prompt.alt .complete svg{
  color:#66bb6a;
}

.loadingScreen{
  position: absolute;
  top:0;
  left:0;
  z-index: 2;
  background-color: #F3F4F6;
}

a {
  text-decoration: none;
}
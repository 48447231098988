.unsubscribeBoard{
    background-image: url("../Images/2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.unsubscribeBoard .form{
    background-color: white;
    grid-template-rows: 1fr;
    width: 80vw;
    height:80vh;
}

.unsubscribeBoard .container{
    grid-template-rows: 300px 1fr 64px;
    gap:36px;
}

.unsubscribeBoard .container .logo img{
    max-height: 100%;
    height: auto;
    max-width: fit-content;
}

.unsubscribeBoard .container .prompt{
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: flex-start;
    text-align: center;
}

.unsubscribeBoard .container .prompt .header{
    font-size:48px;
    font-weight: 500;
    color:dimgray;
}
.unsubscribeBoard .container .prompt div{
    font-size:28px;
    font-weight: 100;
    color:dimgray;
}

.unsubscribeBoard .container .options{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}

.unsubscribeBoard .container .options .option{
    background-color: #104735;
    color:white;
    font-size: 24px;
    max-height: 64px;
}
.unsubscribeBoard .container .options .option.alt{
    background-color: unset;
    color:black;
}
.unsubscribeBoard .container .options .option:hover{
    border:solid 2px #104735;
    color:#104735;
    background-color: unset;
}
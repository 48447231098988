.appHeader{
    background-color: white;
    grid-template-columns: 64px 1fr 50vw;
    position:fixed;
    top:0;
    z-index: 2;
    height:64px;
}
  
.appHeader .tabs > div{
    grid-template-columns: repeat(3, 1fr) 64px 64px;
}

.appHeader .tabs .icon svg{
    width:32px;
    height:32px;
}

.tab{
    grid-template-rows: 4px 1fr 4px;
    position: relative;
    font-size:18px;
}


.tab.alt{
    grid-template-rows: 1fr;
    color:dimgray;
}

.tab .prompt{
    grid-template-columns: 1fr minmax(auto, 48px);
    color:dimgray;
}

.tab.offline .prompt{
    color:gainsboro;
}

.tabs.alt .tab .prompt{
    grid-template-columns: 1fr;
}

.tabs.alt .tab .alert{
    right:8px;
}

.tab.active * :not(.alert){
    color:inherit;
}
.tab.active *{
    font-weight: 500;
    color:#42a5f5;
}

.tab.active .status{
    background-color: #42a5f5;
}

.tab:not(.alt):hover{
    background-color: #e3f2fd;
}

.tab.offline:hover{
    background-color: #EFEFEF;
}
.tab.offline.active .status{
    background-color: #E2E2E2 !important;
}

.tab.offline:hover .status{
    background-color: dimgray !important;
}

.tab.alt:hover{
    background-color: #EFEFEF;
}

.tab:not(.active):hover .status{
    background-color: #1e88e5;
}

.tab .alert{
    color:#f44336 !important;
    position:absolute;
    top:4px;
    right:0px;
}

.appHeader .policyNav{
    width:auto;
    min-width:calc(50vw - 64px);
    grid-template-columns: 64px 1fr 64px;
    color:#0d47a1;
    font-size: 18px;
}

.policyNav .scroll{
    border-radius: 50%;
}
.policyNav .scroll svg{
    width:36px;
    height:36px;
}

.policyNav .scroll:hover{
    background-color:#e3f2fd;
}

.notificationsBtn svg{
    color:#1e88e5; 
}

.notificationsBtn .count{
    position:absolute;
    bottom:4px;
    right:4px;
    background-color: #f44336;
    color: white;
    font-weight: 500;
    border-radius: 16px;
    min-width: 24px;
    padding:2px;
    font-size:14px;
}
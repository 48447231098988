@media (min-width:768px) and (max-width:1024px) {
    body{
        overflow-y: auto;
        overflow-x: hidden;
        min-width: unset;
        height: auto;
    }
    
    #root{
        grid-auto-rows: auto;
        height: auto;
        min-width: unset;
    }

    .App{
        grid-auto-rows: 64px auto;
        overflow-y: auto;
        height: auto;
        gap:8px;
    }

    .overlay{
        position:fixed;
    }
    
    .appHeader .tabs.alt > div{
        grid-template-columns: repeat(4, 1fr) 64px 64px;
    }
    
    .appHeader .policyNav{
        min-width: unset;
        width: 100%;
    }

    .insuredPortal{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        height: auto;
        border-radius: 0;
        width:100%;
    }

    .activity.details{
        grid-template-rows: 1fr;
    }

    .activity.messages{
        grid-template-rows: 48px auto 48px;
    }

    .activity.timeline{
        height: auto;
        overflow: auto;
        grid-template-rows:84px 1fr;
    }

    .policyScroller, .notifications{
        width:80%;
        height:80%;
    }

    .overlay .body{
        overflow-y: auto;
    }

    .messageBoardBottomBar{
        position: fixed;
    }

    /* .loginBoard{
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    } */
}
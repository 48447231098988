.inputField{
    display:inline-grid;
    text-align: left;
    grid-template-rows: repeat(2, 1fr);
    vertical-align: bottom;
    position: relative;
    height:64px;
    width:100%;
    font-weight:100;
    font-size: 18px;
    gap:0px;
  }
  
  .fieldCouple{
    height:56px;
  }
  
  .subFieldCouple{
    width:47.5%;
    display: inline-block;
  }
  
  div > .subFieldCouple ~ .subFieldCouple{
    float:right;
  }
  
  .fieldCouple .inputField:nth-child(1){
    width:47.5%;
    margin-right:2.5%;
  }
  .fieldCouple .inputField:nth-child(2){
    width:47.5%;
    margin-left:2.5%;
  }
  .inputField input{
    width: 100%;
    height: 32px;
    border-style: none;
    padding: 8px;
    font-size: 18px;
    border-bottom:solid 2px #EFEFEF;
  }
  
  .inputField.passwordContainer{
    height:105px;
  }
  
  .passwordRequirements{
    position: relative;
    border-radius:12px;
    width:100%;
    height:42px;
    line-height: 16px;
    z-index: 2;
    color:gray;
  }
  
  .passwordRequirements.active{
    color:#EF5350;
  }
  
  .inputField input.active{
    width: 100%;
    height: 32px;
    border-style: none;
    background-color: white;
    padding: 8px;
    border: solid 1px gainsboro;
  }
  .inputField input.preFace{
    padding-left:15px;
  }
  .inputField input:read-only{
    color:dimgray;
  }
  .inputField input:focus{
    background-color: whitesmoke;
    border-color: #1e88e5;
    outline:none;
  }
  .fieldName{
    padding-left:8px;
    font-size:18px;
    font-weight: 400;
    color:gray;
  }
  
  .showPasswordBtn{
    position:absolute;
    border-radius:50%;
    height:24px;
    width:24px;
    right:-28px;
    bottom:4px;
    color:gray;
  }
  .showPasswordBtn:hover{
    cursor:pointer;
  }
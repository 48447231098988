.loginBoard{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    height:100%;
    width:100%;
    background-image: url("../Images/2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.loginBlock{
    width:450px;
    height:auto;
    background-color: white;
    grid-template-rows: 96px auto 8px 1fr;
    gap:8px;
    max-height: calc(100vh - 16px);
    max-width: calc(50vw - 32px);
}

.loginBlock .message{
    color:dimgray;
    font-weight: 100;
    font-size: 18px;
}

.loginBlock .wrap{
    overflow:auto;
}

.loginBlock .form{
    grid-template-rows: 1fr repeat(2, auto);
    font-size: 24px;
    gap:16px;
    height: auto;
}
.loginBlock.reset .form, .loginBlock.twoFactor .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.loginBlock .form .fields{
    grid-template-rows: repeat(auto-fill, 64px);
    gap: 8px;
}
.loginBlock .form .fields .field{
    grid-template-columns: 64px 1fr 64px;
}
.loginBlock .form .fields .field :first-child svg{
    width:36px;
    height:36px;
    color: #719085;
}

.loginBlock.login .form .options{
    grid-template-rows: repeat(2, 1fr);
    gap:8px;
}

.passwordReset{
    color:gray;
    font-size: 18px;
}
.passwordReset:hover{
    background-color: #EFEFEF;
    color:dimgray;
    outline:solid 2px gray;
}

.loginBoard input{
    border-radius: 5px;
    color:dimgray;
}
.loginBoard .inputField input:focus{
    outline:solid 2px gray;
    border-color: transparent;
    background-color: white;
}

.goBack{
    grid-template-columns: 48px auto;
    color:gray;
    gap:8px;
    border-bottom:solid 2px transparent;
}
.goBack:hover{
    color:dimgray;
    background-color: whitesmoke;
    border-color: #104735;
}

.loginBlock .field .active svg{
    color:#104735 !important;
}

.loginBlock .btnWIcon{
    border-color:#104735;
    color:#104735;
    grid-template-columns: 24px 1fr 24px;
}

.loginBlock .active.btnWIcon{
    background-color:#104735;
    color:white;
}

.loginBlock .active.btnWIcon:hover{
    background-color: white;
    color:#104735;
}

.loginBlock .header{
    grid-template-columns: 96px 1fr;
    font-size: 24px;
    color:#104735;
}

.lockIcon{
    height:48px;
    width:48px;
    border-radius: 50%;
    outline:solid 2px #104735;
}

.lockIcon svg{
    color:#104735;
    width:36px;
    height:auto;
}

.loginBlock .prompt{
    color:dimgray;
    font-weight: 100;
}

.loginBlock .prompt.alt{
    grid-template-rows: 36px repeat(6, 24px);
}


.loginBtn{
    height:48px;
}

.loginBlock.create .form{
    grid-template-rows: 48px auto auto 48px auto;
    font-size: 24px;
    gap:16px;
}

.errorStatus{
    background-color: #ffebee;
    color:#e53935;
    font-weight: 100;
    border-radius: 5px;
}

.videoContainer{
    padding:32px;
}

.introVideo{
    position: relative;
    padding-bottom: 56.25%;
    height: auto;
    width: 100%;
    max-width: calc(50vw - 32px);
}

.introVideo video{
    border:none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}
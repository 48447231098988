.insuredPortal{
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
    position: relative;
}

.progress{
    transition: 100ms !important;
}

.policyForm, .policyForm *:not(.progress) {
    transition: 0ms !important;
}

.policyForm{
    grid-template-rows: minmax(100px, auto) minmax(132px, auto) 1fr;
    gap:8px;
}

.insuredPortal iframe{
    border:none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.contactBlock, .bidBlock{
    grid-template-rows:minmax(36px, auto) 1fr;
}

.bidInfo{
    min-height:104px;
}

.bidBlock .content{
    grid-template-columns: 64px repeat(3, 1fr);
}

.bidInfo:not(.inactive){
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: 500;
    gap:8px;
}


.bidInfo.inactive.Preparation,
.bidInfo.inactive.Preparation .section,
.bidInfo.inactive.Preparation .progressBar {
    background-color: #fffde7;
    border-color:#f57f17;
}
.bidInfo.Preparation .progress{
    background-color: #fdd835;
}

.bidInfo.Preparation .prompt * {
  color: #f57f17;
}

.bidInfo.Preparation .section .divider {
  background-color: #f57f17;
}

.bidInfo.inactive.Preparation svg {
  color: #f57f17;
}

.bidInfo.inactive.Valuation,
.bidInfo.inactive.Valuation .section,
.bidInfo.inactive.Valuation .progressBar {
    background-color: #f1f8e9;
    border-color:#33691e;
}
.bidInfo.Valuation .progress{
    background-color: #8bc34a;
}

.bidInfo.Valuation .prompt * {
  color: #33691e;
}

.bidInfo.Valuation .section .divider {
  background-color: #33691e;
}

.bidInfo.inactive.Valuation svg {
  color: #33691e;
}

.bidInfo.closed .section{
    background-color: #F3F4F6 !important;
    border-color:#E2E2E2 !important;
}
.bidInfo.closed .section *{
    color:gray !important;
}
.bidInfo.closed .section .divider{
    background-color:gray !important;
}

.bidInfo.inactive{
    grid-template-columns: 96px 1fr 96px;
    font-weight: 500;
    gap:8px;
    border-style:dashed;
    border-width: 2px;
    border-radius: 5px;
}

.bidInfo.inactive .prompt{
    grid-template-rows: 1fr 36px;
    font-size: 24px;
}
.bidInfo.inactive .progressBar{
    width: calc(100% - 16px);
    height: 10px;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    overflow: hidden;
}
.bidInfo.inactive .progressBar .progress{
    height:100%;
}

.bidInfo.inactive svg, .bidInfo.onHold svg{
    width:48px;
    height:48px;
}

.bidInfo .section{
    grid-template-rows: 1fr 8px auto;
    border-radius: 5px;
    gap: 8px;
}
.bidInfo .section.split{
    grid-template-rows: 1fr 8px auto;
    gap:8px;
}
.bidInfo .section.split .icon{
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    font-size: 20px;
}
.bidInfo .section.split .icon > div:first-of-type :nth-child(1){
    font-weight: 400;
}

.bidInfo .section.split .icon > div:first-of-type :nth-child(2){
    font-weight: 500;
    font-size: 24px;
}

.bidInfo .section.split .icon > div:last-of-type :nth-child(1){
    font-weight: 100;
}

.bidInfo .section.split .icon > div:last-of-type :nth-child(2){
    font-weight: 300;
    font-size: 24px;
}

.bidInfo .section:not(.alt) :first-child{
    grid-template-columns: auto auto;
}

.bidInfo .section.one{
    background-color: #e8f5e9;
    border:solid 2px #4caf50;
}
.bidInfo .section.one .divider{
    background-color: #4caf50;
}
.bidInfo .section.two{
    background-color: #ede7f6;
    border:solid 2px #6a1b9a;
}
.bidInfo .section.two .divider{
    background-color: #6a1b9a;
}
.bidInfo .section.three{
    background-color: #fff3e0;  
    border:solid 2px #ff9800;
}
.bidInfo .section.three .divider{
    background-color: #ff9800;
}

.bidBlock .icon{
    font-size:24px;
    width: auto;
    gap:8px;
}
.bidBlock .icon svg{
    height: 32px;
    width: auto;
}

.bidInfo .data{
    color:dimgray;
}

.bidInfo .section.one .icon, .bidInfo .section.one .data{
    color:#4caf50;
}
.bidInfo .section.two .icon, .bidInfo .section.two .data{
    color:#6a1b9a;
}
.bidInfo .section.three .icon, .bidInfo .section.three .data{
    color:#ff9800;
}

.bidInfo.onHold{
    background-color: #fbe9e7;
    border-color: #ff7043;
    color: #ff7043;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    grid-template-columns: 1fr auto;
}

.bidInfo.inactive.closed{
    background-color: #F3F4F6;
    border-color: gray;
    border-style: solid;
    grid-template-columns: 1fr auto;
}

.bidInfo.inactive.closed svg{
    color:gray;
}

.bidInfo.onHold .prompt{
    font-size:24px;
}

.bidInfo.inactive.closed .prompt{
    color:dimgray;
    grid-template-rows: 1fr;
}

.bidInfo.onHold .icon2{
    display:grid;
    grid-template-rows: 1fr 24px;
}

.bidInfo.inactive.closed .icon2{
    display:grid;
    grid-template-rows: 1fr 24px;
    color:dimgray;
}

.bidInfo.inactive.closed .progressBar{
    border-color:dimgray;
    background-color:#E2E2E2;
}
.bidInfo.inactive.closed .progressBar .progress{
    background-color: gray;
}

.formBlock{
    grid-template-rows:36px min-content 36px min-content;
    overflow: auto;
    gap: 8px;
}

.policyForm .header{
    color:dimgray;
    font-weight: 500;
    font-size:20px;
    grid-template-columns: 36px 1fr 36px;
}

.policyForm .form .header{
    color:dimgray;
    font-weight: 100;
    font-size:20px;
}

.contactBar{
    grid-template-columns: repeat(2, 1fr);
    gap:8px;
}

.contactBlock .header{
    grid-template-columns: 36px 1fr auto 56px;
    grid-template-rows: 56px;
}

.contactBlock .header .profilePic svg{
    width:48px;
    height:48px;
}

.header .profilePic img{
    width:56px;
    height:auto;
    border-radius: 50%;
    overflow: hidden;
    background-color: whitesmoke;
}

.contactBar .option{
    grid-template-columns: auto 1fr;
    gap: 8px;
    color: #1e88e5;
    font-size:20px;
}
.contactBar .option.contactInfo{
    grid-template-columns: 36px 1fr;
}
.contactBar .option.contactInfo > div{
    grid-template-columns: auto 1fr;
    gap: 8px;
}

.contactBar .option.contactInfo:hover{
    background-color: #e3f2fd;
}

.contactBar .option svg{
    height: 36px;
    width: 36px;
}

.policyForm .form{
    gap:24px;
}

.policyForm .form .inputField{
    min-height: 64px;
}

.policyForm .form > div{
    grid-template-rows: repeat(auto-fill, 64px);
    gap:8px;
}
.policyForm .form .double{
    grid-template-columns: repeat(2, 1fr);
}

.policyForm .form .quad{
    grid-template-columns: repeat(4, auto);
}
